import { useState, useEffect } from 'react';
import { calculateLocalTime } from '@/sections/events/utils';

// This is a hook to ensure that the local time is calculated on the client side to avoid hydration errors
export function useLocalTime(timestamp: string) {
  const [localDate, setLocalDate] = useState<string>('');
  const [localTime, setLocalTime] = useState<string>('');

  useEffect(() => {
    const { localDate, localTime } = calculateLocalTime(timestamp);
    setLocalDate(localDate);
    setLocalTime(localTime);
  }, [timestamp]);

  return { localDate, localTime };
}
