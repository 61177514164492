import { CLEARBIT_COOKIE, UTM_COOKIE } from './constants';
import { getCookie } from 'cookies-next';

type useUtmParamsReturnType = {
  [key: string]: string;
};

// will only return values in a "use client" component
export function useUtmParams(): useUtmParamsReturnType {
  const utmParams = getCookie(UTM_COOKIE);
  return utmParams ? JSON.parse(utmParams.toString()) : {};
}

export function useClearbitParams(): useUtmParamsReturnType {
  const clearbitParams = getCookie(CLEARBIT_COOKIE);
  return clearbitParams ? JSON.parse(clearbitParams.toString()) : {};
}
