'use client';

import { CalendarIcon, ClockIcon, TicketIcon, ArrowRightIcon, MapPinIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { type Event, eventTypes } from './events';
import Button from '@/components/Button';
import { useLocalTime } from '@/lib/useLocalTime';

type EventCardProps = {
  variant?: 'upcoming' | 'past';
  slug?: string;
  videoLink?: string;
} & Omit<Event, 'videoLink'>;

export function EventCard({
  variant = 'upcoming',
  title,
  description,
  eventType,
  timeStamp,
  locationShort,
  isFree,
  slug,
  externalLink,
  date,
  time,
  videoLink,
}: EventCardProps) {
  const { localDate, localTime } = useLocalTime(timeStamp);

  return (
    <div
      className={clsx(
        'relative flex flex-col justify-between rounded-3xl p-8',
        variant === 'past' ? 'bg-white text-gray-1' : 'bg-[#202328]',
      )}
      data-testid={`event-card-${variant}`}
    >
      <div>
        <div className="flex justify-between">
          <div className="mb-6 max-w-3xl lg:mb-8">
            <div className={clsx('font-bold', eventType === eventTypes.online ? 'text-[#00C851]' : 'text-[#FB3]')}>
              {eventType}
            </div>
            <h3 className="text-h6 mb-2 md:mb-4 lg:line-clamp-2">{title}</h3>
            <p className={clsx('text-body line-clamp-2', variant === 'past' ? 'text-gray-3' : 'text-gray-4')}>
              {description}
            </p>
          </div>
          <div className={clsx('hidden', variant === 'upcoming' && 'lg:flex lg:justify-end')}>
            <Button
              href={externalLink ? externalLink : `/events/${slug}`}
              variant="ghost-light"
              outline
              className="flex items-center gap-2 text-white"
            >
              Learn more
              <ArrowRightIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>

        <div
          className={clsx(
            'text-body flex flex-col gap-4 border-t border-[#2E3135] pt-4 md:flex-row md:items-center ',
            variant === 'past'
              ? 'border-[#E5E5E5] text-gray-2 lg:flex-col lg:items-start'
              : 'border-[#2E3135] text-gray-5',
          )}
        >
          <div className="flex items-center gap-2">
            <CalendarIcon className="h-5 w-5" />
            <span>{eventType === 'Online' ? localDate : date}</span>
          </div>
          {locationShort && (
            <div className="flex items-center gap-2">
              <MapPinIcon className="h-5 w-5" />
              <span>{locationShort}</span>
            </div>
          )}
          <div className="flex items-center gap-2">
            <ClockIcon className="h-5 w-5" />
            <span>{eventType === 'Online' ? localTime : time}</span>
          </div>
          <div className="flex items-center gap-2">
            <TicketIcon className="h-5 w-5" />
            <span>{isFree ? 'FREE' : 'PAID'}</span>
          </div>
        </div>
      </div>

      <div className={clsx('mt-6 flex justify-end', variant === 'past' ? '' : 'lg:hidden')}>
        <Button
          href={externalLink ? externalLink : `/events/${slug}`}
          variant={variant === 'past' ? 'ghost-dark' : 'ghost-light'}
          outline
          className="flex items-center gap-2"
        >
          {videoLink && videoLink !== '' ? 'Watch recording' : 'Learn more'}
          <ArrowRightIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
}
export default EventCard;
