import { Event } from './events';

// TODO: fix this once we figure out the date format
export function eventInPast(event: Event) {
  const eventDate = new Date(event.timeStamp);
  const today = new Date();

  // Reset time to allow for comparison of dates only
  // this has the effect of allowing events "today" to be shown as upcoming
  eventDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  return eventDate < today;
}

export function groupEventsByDate(events: Event[]) {
  // Sort all events first (ascending order)
  const sortedEvents = [...events].sort((a, b) => new Date(a.timeStamp).getTime() - new Date(b.timeStamp).getTime());

  const pastEvents: Event[] = [];
  const upcomingEvents: Event[] = [];

  for (const event of sortedEvents) {
    if (eventInPast(event)) {
      pastEvents.push(event);
    } else {
      upcomingEvents.push(event);
    }
  }

  // most recent first
  pastEvents.reverse();

  return { pastEvents, upcomingEvents };
}

export function calculateLocalTime(timeStamp: string) {
  const dateObj = new Date(timeStamp);
  const localDate = dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  });
  const localTime = dateObj.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return { localDate, localTime };
}
