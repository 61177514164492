export const utmFields = ['utm_term', 'utm_source', 'utm_content', 'utm_medium', 'utm_campaign'];

export const clearbitFields = [
  'Clearbit_Company_Alexa_Rank',
  'Clearbit_Company_City',
  'Clearbit_Company_Country',
  'Clearbit_Company_Domain',
  'Clearbit_Company_Employees_Range',
  'Clearbit_Company_Funding',
  'Clearbit_Company_Industry',
  'Clearbit_Company_Industry_Group',
  'Clearbit_Company_Name',
  'Clearbit_Company_Sector',
  'Clearbit_Company_State',
  'Clearbit_Company_Subindustry',
  'Clearbit_Company_Tags',
  'Clearbit_Company_Tech',
  'Clearbit_Company_Type',
  'Clearbit_Traffic_Type',
];
