export const eventTypes = {
  online: 'Online',
  inPerson: 'In-person',
} as const;

export type EventType = (typeof eventTypes)[keyof typeof eventTypes];
export type Speaker = {
  name: string;
  title: string;
  company: string;
  bio: string;
  headshotLink?: string;
};

export type BaseEvent = {
  title: string;
  description: string;
  eventType: EventType;
  date: string;
  time: string;
  timeStamp: string; //  ISO 8601
  locationShort?: string;
  isFree: boolean;
  topics?: string[];
  locationFull?: string;
};

type RegisterForm = {
  pardotFormSubmitLink: string;
  zoomId: string;
};

export type InternalEvent = BaseEvent & {
  videoLink?: string;
  externalLink?: never;
  slug: string;
  fullDescription: string | JSX.Element;
  discussionTopics: string[];
  locationFull?: string;
  speakers: Speaker[];
  heroImageLink: string;
  // This intersection type ensures an InternalEvent either has both registration fields (pardotFormSubmitLink & zoomId)
  // or neither of them by using 'never' type to make the fields unavailable
} & (RegisterForm | { pardotFormSubmitLink?: never; zoomId?: never });

export type ExternalEvent = BaseEvent & {
  externalLink: string;
};

export type Event = InternalEvent | ExternalEvent;

export const isInternalEvent = (event: Event): event is InternalEvent => {
  return !('externalLink' in event);
};

export const events: Event[] = [
  {
    slug: 'developer-productivity-Apache-Maven-18-hacks-accelerate-builds-and-tests-01-25',
    title: 'Developer Productivity with Apache Maven™:\n18 hacks to accelerate your builds and tests',
    description:
      'Join Brian Demers as he shares a comprehensive list of hacks to make your Maven experience more productive and less miserable.',
    eventType: 'Online',
    date: 'January 23, 2025',
    time: '08:00 PST',
    timeStamp: '2025-01-23T17:00:00+01:00',
    locationShort: '',
    isFree: true,
    topics: ['Developer productivity', 'Maven', 'Acceleration'],
    pardotFormSubmitLink: 'https://go.gradle.com/l/68052/2024-12-09/n3v8qy',
    zoomId: '83889353471',
    fullDescription:
      "If you're a regular Apache Maven user, you know that build times can quickly add up, eating into your development time and slowing down your feedback loop—whether you're waiting for a local build to finish or watching a pull request on CI inch towards merge, every minute counts.\n\nIn this webinar by Brian Demers, Java Champion and veteran Apache Maven contributor, we will review a comprehensive list of tips and hacks to make your Maven experience more productive and less miserable. \n\nSome of these tips are low-hanging fruit for even Maven beginners, others require more thought and planning for Maven experts. Either way, following this advice will make a meaningful difference to your developer productivity—plus, being able to observe and report productivity boosts to your management team is always a good thing!",
    heroImageLink: 'EVENT_mavenHacksWebinar-JAN2025.jpg',
    discussionTopics: [
      'Sensible Maven tips and tricks learned over 15 years (low hanging fruit)',
      'Demo: the power of build caching, demonstrated with Apache SCIMple',
      'Leveraging observability to manage "Maven dependency hell"',
      'Your toughest questions for the Q&A',
    ],
    speakers: [
      {
        name: 'Brian Demers',
        title: 'Developer Advocate',
        company: 'Gradle',
        bio: 'Brian Demers is a Developer Advocate at Gradle, a Java Champion, and an Apache Member who contributes to the Directory, Maven, and Shiro projects. He spends much of his day contributing to OSS projects by writing code, tutorials, blogs, and answering questions. In addition to typical software development, Brian also has a passion for fast builds and automation.\n\nAway from the keyboard, Brian is a beekeeper and can likely be found playing board games. You can find him on Twitter at https://twitter.com/briandemers.',
        headshotLink: 'brianDemers.png',
      },
    ],
    videoLink: 'https://www.youtube.com/watch?v=280o9AupjFI',
  },
  {
    slug: 'writing-tests-is-hard-fixing-them-is-harder-11-24',
    title: 'Writing tests is hard. Fixing them is harder.',
    description:
      'Learn several techniques for troubleshooting tests and understand what information you need to identify problems.',
    eventType: 'Online',
    date: 'November 13, 2024',
    time: '16:00 GMT',
    timeStamp: '2024-11-13T16:00:00+00:00',
    locationShort: '',
    isFree: true,
    topics: ['Developer productivity', 'Acceleration', 'Troubleshooting'],
    fullDescription:
      "Testing is a Good Thing, right? Especially automated testing. But the phrase 'good things come to those who wait' doesn't appeal to a busy developer. When a test starts failing, dropping everything to find and fix the problem can be a frustrating experience.\n\nIn this webinar, Trisha Gee will cover several techniques for troubleshooting tests and go through some of the information you need to identify problems. She will also rant about flaky tests, and how toxic they are to developer productivity. Then she'll share some tips on writing tests, with an eye to making troubleshooting and debugging easier. Finally, she'll do a live coding demonstration and offer up some 'best practices'. Oh, and AI & machine learning will be mentioned at some point.",
    discussionTopics: [
      'Why is it hard to troubleshoot tests?',
      'What steps should you take to fix a test?',
      'Why are flaky tests toxic?',
      'How can you write tests that are easier to fix?',
    ],
    speakers: [
      {
        name: 'Trisha Gee',
        title: 'Lead Technical Evangelist',
        company: 'Gradle',
        bio: 'Trisha is a Java Champion, software engineer, developer advocate and author who is passionate about developer productivity and happiness. She has expertise in Java high performance systems and dabbles with Open Source development.',
        headshotLink: 'trishaGee.png',
      },
    ],
    heroImageLink: 'EVENT_testsWebinar-NOV2024.jpg',
    videoLink: 'https://www.youtube.com/watch?v=I7H2EtVvX4c',
  },
  {
    slug: 'scala-developers-crack-open-the-black-box-of-sbt-10-24',
    title: 'Scala developers: Crack open the black box of sbt with build and test observability',
    description:
      'Learn how Scala developers using sbt can finally observe everything that happens during the build and test feedback cycle and reap tangible developer productivity gains.',
    eventType: 'Online',
    date: 'October 10, 2024',
    time: '15:00 GMT',
    timeStamp: '2024-10-10T15:00:00+00:00',
    locationShort: '',
    isFree: true,
    topics: ['Developer productivity', 'Metrics', 'sbt'],
    fullDescription:
      "The Scala ecosystem has many OSS technologies, yet it's underserved when it comes to enterprise tooling. Scala developers rarely have actionable build insights when something goes wrong with sbt.\n\nUntil now! Develocity Build Scan® offers Scala developers deep insights into what's going on inside the black box of sbt—including shareable links to specific build failures, flaky tests, performance regressions, and more.\n\nJoin Iulian Dragos to learn how Scala developers using sbt can finally observe everything that happens during the build and test feedback cycle, leading to tangible developer productivity gains.",
    discussionTopics: [
      'Build insights like long-running tasks, bottlenecks, and common failure patterns',
      'Flaky and slow test identification and resolution',
      'Long-term performance trends and analysis',
      'Bonus: how Build Cache for sbt reduced Scala 3 builds from 20-minutes to 90-seconds',
    ],
    speakers: [
      {
        name: 'Iulian Dragos',
        title: 'Principal Software Engineer',
        company: 'Gradle',
        bio: 'Iulian is a seasoned software engineer with over a decade of experience in the Scala programming language. He was part of the original Scala team, where he made significant contributions to the design and implementation of the language. He is a regular speaker at conferences and meetups, and he is also an active contributor to open source projects related to Scala.',
        headshotLink: 'iulianDragos.png',
      },
    ],
    heroImageLink: 'EVENT_scalaWebinar-OCT2024.jpg',
    videoLink: 'https://www.youtube.com/watch?v=FMWEj4gaEJQ',
  },
  {
    slug: 'gradle-9-is-coming-whats-in-it-for-developers-09-24',
    title: "Gradle 9 is coming: What's in it for developers?",
    description:
      "Join Louis Jacomet, Senior Lead Software Engineer at Gradle, to learn more about what's coming in Gradle 9.",
    eventType: 'Online',
    date: 'September 24, 2024',
    time: '15:00 GMT',
    timeStamp: '2024-09-24T15:00:00+00:00',
    locationShort: '',
    isFree: true,
    topics: ['Gradle Build Tool', 'Developer productivity'],
    fullDescription:
      "The release of Gradle Build Tool 9.0 is approaching—so what should developers know?\n\nGradle 9 is packed with features that continue to evolve the developer UX. One focus is on scalability—making builds and tests as fast as possible, even for massive projects. Another focus is on comprehensibility—making Gradle easier to adopt and use for teams of any size and skill level.\n\nJoin Louis Jacomet, Senior Lead Software Engineer at Gradle Inc., conference speaker and community organizer, to learn more about what's coming in Gradle 9.",
    discussionTopics: [
      'Continued investment in configuration caching',
      'Upcoming isolated projects feature',
      'Higher level modeling of software projects',
      'Separation of concerns for build users and build authors',
    ],
    speakers: [
      {
        name: 'Louis Jacomet',
        title: 'Sr. Lead Software Engineer',
        company: 'Gradle',
        bio: "Louis has been perfecting his knowledge of Java for 20+ years. A developer at heart, his role has evolved to include technical team leading, coaching, and management. In addition to the bits and bytes, he's interested in creating productive project teams. After working for Terracotta, he's now part of the Gradle Build Tool team.",
        headshotLink: 'louisJacomet.png',
      },
    ],
    heroImageLink: 'EVENT_gradle9Webinar-SEP2024.jpg',
    videoLink: 'https://www.youtube.com/watch?v=FBCqeY6AhMM',
  },
  {
    title: 'DPE Summit',
    description:
      'Two days, dozens of sessions, countless learnings. Join our annual event dedicated to Developer Productivity Engineering and Developer Experience.',
    eventType: 'In-person',
    date: 'September 24, 2024',
    time: 'N/A',
    timeStamp: '2024-09-24T00:00:00+00:00',
    locationShort: 'San Francisco, CA',
    isFree: false,
    externalLink: 'https://dpe.org/summit2024/',
    topics: ['Developer productivity', 'Acceleration', 'Troubleshooting', 'Metrics'],
  },
  {
    slug: 'gradle-artifact-transforms-and-deeper-build-insights-08-24',
    title: 'Gradle Artifact Transforms and deeper build insights',
    description:
      'Get the basics of Artifact Transforms and then explore real-world use cases where Artifact Transforms insights from a Develocity Build Scan® can be used to identify and address bottlenecks in your build process.',
    eventType: 'Online',
    date: 'August 15, 2024',
    time: '15:00 GMT',
    timeStamp: '2024-08-15T15:00:00+00:00',
    locationShort: '',
    isFree: true,
    topics: ['Developer productivity', 'Acceleration', 'Troubleshooting', 'Gradle Build Tool'],
    fullDescription:
      "Artifact Transforms play a crucial role in streamlining your development workflow, yet they're often overlooked as a mechanism for boosting developer productivity. In this webinar, we'll introduce the basics of Artifact Transforms and demo the initial steps of implementing a simple artifact transform. Next, we'll show how integrating this with Gradle's caching mechanism can help reduce build times. Finally, we'll look at real-world use cases where Artifact Transforms insights from a Develocity Build Scan® can be used to pinpoint and rapidly address bottlenecks in your build process.",
    discussionTopics: [
      'Introducing the basics of Artifact Transforms',
      'Demo implementation of a simple artifact transform',
      "How to integrate this with Gradle's caching mechanism",
      'Tips for making Artifact Transforms a more effective and efficient part of your build process',
      'Real-world use cases of using Build Scan insights on Artifact Transforms to address build process bottlenecks',
    ],
    speakers: [
      {
        name: 'Nelson Osacky',
        title: 'Lead Solutions Engineer',
        company: 'Gradle',
        bio: 'Nelson Osacky is a former Square employee based out of San Francisco. He was also part of SoundCloud in Berlin. Now he is speeding up builds around the world at Gradle.',
        headshotLink: 'nelsonOsacky.png',
      },
      {
        name: 'Inaki Villar',
        title: 'Sr. Solutions Engineer',
        company: 'Gradle',
        bio: 'Based in Los Angeles, Inaki is working on providing solutions and optimizations for clients of Develocity in Gradle/Maven builds. Before this role, Inaki focused on Android and Build performance for companies like Tinder, Agoda, Synchronous, Ryanair and Ideaknow. Originally from Mallorca, Spain, he enjoys watching real football and watersports.',
        headshotLink: 'inakiVillar.png',
      },
    ],
    heroImageLink: 'EVENT_artifactTransformsWebinar-AUG2024.jpg',
    videoLink: 'https://www.youtube.com/watch?v=VmRvCHm2ip4',
  },
  {
    title: 'DPE Tour: Washington D.C.',
    description:
      'Join us for an afternoon focused on Developer Productivity Engineering and Developer Experience with talks from Capital One, Airbnb, and American Airlines.',
    eventType: 'In-person',
    date: 'July 18, 2024',
    time: '19:00 GMT',
    timeStamp: '2024-07-18T19:00:00+00:00',
    locationShort: 'Arlington, VA',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-washington-dc-tour/',
    topics: ['Developer productivity'],
  },
  {
    title: 'How Quarkus accelerates merging Apache Maven PR builds with Develocity',
    description: '',
    eventType: 'Online',
    date: 'July 11, 2024',
    time: '17:00 GMT',
    timeStamp: '2024-07-11T17:00:00+00:00',
    locationShort: '',
    isFree: true,
    externalLink: 'https://dpe.org/lowdown/how-quarkus-accelerates-merging-maven-pr-builds-with-develocity',
    topics: ['Maven', 'Acceleration'],
  },
  {
    title: 'DPE Tour: Dallas',
    description:
      'Join us in Dallas for an afternoon focused on Developer Productivity Engineering and Developer Experience, with talks from Airbnb, Netflix, and American Airlines.',
    eventType: 'In-person',
    date: 'March 7, 2024',
    time: '15:00 CDT',
    timeStamp: '2024-03-07T15:00:00-06:00',
    locationShort: 'Plano, TX',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-dallas-tour/',
    topics: ['Developer productivity', 'Troubleshooting'],
  },
  {
    title: 'DPE Tour: Seattle',
    description:
      'Join us in Seattle for an afternoon focused on Developer Productivity Engineering and Developer Experience, with talks from Microsoft and Meta.',
    eventType: 'In-person',
    date: 'March 18, 2024',
    time: '15:00 PST',
    timeStamp: '2024-03-18T15:00:00-08:00',
    locationShort: 'Seattle, WA',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-seattle-tour/',
    topics: ['Developer productivity', 'Metrics', 'Acceleration'],
  },
  {
    title: 'DPE Tour: Atlanta',
    description:
      'Join us at DevNexus in Atlanta for an afternoon focused on Developer Productivity Engineering and Developer Experience, with talks from Netflix and Gradle.',
    eventType: 'In-person',
    date: 'April 9, 2024',
    time: '13:30 EDT',
    timeStamp: '2024-04-09T13:30:00-04:00',
    locationShort: 'Atlanta, GA',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-atlanta-tour/',
    topics: ['Developer productivity', 'Metrics'],
  },
  {
    title: 'DPE Tour: New York City',
    description:
      'Join us in the heart of New York City for an afternoon focused on Developer Productivity Engineering and Developer Experience, with talks from OpenAI, Microsoft, Netflix, and AWS.',
    eventType: 'In-person',
    date: 'April 17, 2024',
    time: '15:00 EDT',
    timeStamp: '2024-04-17T15:00:00-04:00',
    locationShort: 'New York, NY',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-new-york-tour/',
    topics: ['Developer productivity', 'Metrics', 'Troubleshooting'],
  },
  {
    title: 'DPE Tour: Berlin',
    description:
      'Join us in Berlin for an afternoon focused on Developer Productivity Engineering and Developer Experience with talks from Delivery Hero, Gradle, and Zalando.',
    eventType: 'In-person',
    date: 'June 11, 2024',
    time: '15:00 CST',
    timeStamp: '2024-06-11T15:00:00+02:00',
    locationShort: 'Berlin, Germany',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-berlin-tour/',
    topics: ['Developer productivity', 'Metrics', 'Troubleshooting'],
  },
  {
    title: 'DPE Tour: Boston',
    description:
      'Join us in Boston for an afternoon focused on Developer Productivity Engineering and Developer Experience, with talks from Toast, Netflix, and Meta.',
    eventType: 'In-person',
    date: 'June 12, 2024',
    time: '12:30 EDT',
    timeStamp: '2024-06-12T12:30:00-04:00',
    locationShort: 'Boston, MA',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-boston-tour/',
    topics: ['Developer productivity', 'Metrics', 'Troubleshooting'],
  },
  {
    title: 'DPE Tour: London',
    description:
      'Join us in London for an afternoon focused on Developer Productivity Engineering and Developer Experience, with talks from Red Hat, Netflix, Gradle, Meta, American Airlines and Spotify.',
    eventType: 'In-person',
    date: 'June 25, 2024',
    time: '15:30 BST',
    timeStamp: '2024-06-25T15:30:00+01:00',
    locationShort: 'London, England',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-london-tour/',
    topics: ['Developer productivity', 'Metrics', 'Troubleshooting'],
  },
  {
    title: 'DPE Tour: San Francisco',
    description:
      'Join us for a three hour event focused on Developer Productivity Engineering and Developer Experience at Pinterest HQ.',
    eventType: 'In-person',
    date: 'January 23, 2025',
    time: '15:00 PST',
    timeStamp: '2025-01-23T15:00:00-08:00',
    locationShort: 'San Francisco, CA',
    isFree: true,
    topics: ['Developer productivity', 'Acceleration', 'Troubleshooting', 'Metrics'],
    externalLink: 'https://dpe.org/dpe-san-francisco-tour-2025/',
  },
  {
    slug: 'observing-build-ci-productivity-oss-projects',
    heroImageLink: 'observing-build-ci-productivity-oss-projects.jpg',
    title: 'Observing build and CI productivity in your favorite OSS projects',
    description: "We'll share insights gained from the data from over 50k OSS project builds/wk.",
    pardotFormSubmitLink: 'https://go.gradle.com/l/68052/2025-01-29/n3vqf1',
    zoomId: '85651539459',
    eventType: 'Online',
    date: 'February 27, 2025',
    time: '08:00 PST',
    timeStamp: '2025-02-27T08:00:00-08:00',
    locationShort: '',
    isFree: true,
    topics: ['Developer productivity', 'Metrics', 'Gradle Build Tool'],
    fullDescription:
      "If you use Spring Framework, JUnit Platform, Testcontainers, or Hibernate, then you're using open-source software that leverages Develocity for accelerated builds/tests and observability into failures, flaky tests, performance regressions, and more.\n\nWe're proud to sponsor free (public) instances of Develocity for over 20 well-known open-source projects, including those from the Apache Software Foundation, Spring Framework, Commonhaus Foundation, Micronaut Foundation, Kotlin Foundation, Scala Center, and many more.\n\nIn this webinar with Gašper Kojek, Solutions Engineer at Gradle, we'll review surprising insights gained from the data from over 50,000 OSS project builds per week.",
    discussionTopics: [
      'Key insights from analyzing 50k+ OSS project builds per week',
      'How popular OSS projects leverage build and test observability',
      'Common patterns in build/test failures and performance regressions',
      'Live demo: exploring public build data from Spring Framework, JUnit, and more',
    ],
    speakers: [
      {
        name: 'Gašper Kojek',
        title: 'Solutions Engineer',
        headshotLink: 'gašperKojek.png',
        company: 'Gradle',
        bio: 'Based in Slovenia, Gašper is a Solutions Engineer working on providing solutions and optimizations for clients of Develocity in Gradle/Maven builds. Coming from an Android background, he is no stranger to long build times and how to improve them. He worked with a diverse Android stack across multiple projects, as well Docker, Jenkins, Github Actions and Develocity, after which he found himself in helping others at Gradle.',
      },
    ],
    videoLink: 'https://www.youtube.com/watch?v=rwfNfLLWvnM',
  },
  {
    title: 'DPE Tour: Palo Alto',
    description:
      'Join us for a four hour event focused on Developer Productivity Engineering and Developer Experience at Meta HQ.',
    eventType: 'In-person',
    date: 'March 25, 2025',
    time: '14:00 PST',
    timeStamp: '2025-03-25T14:00:00-08:00',
    locationShort: 'Menlo Park, CA',
    isFree: true,
    externalLink: 'https://dpe.org/dpe-palo-alto-tour-2025/',
    topics: ['Developer productivity', 'Acceleration', 'Troubleshooting', 'Metrics'],
  },
  {
    slug: 'troubleshooting-gradle-maven-builds-develocity-03-27',
    title: 'Troubleshooting Gradle and Maven builds with Develocity',
    description:
      'In this talk by Stefan Wolf, Principal Software Engineer at Gradle, we take a look at troubleshooting local and CI builds with Gradle and Apache Maven.',
    eventType: 'Online',
    date: 'March 27, 2025',
    time: '08:00 PST',
    timeStamp: '2025-03-27T08:00:00-07:00',
    locationShort: '',
    isFree: true,
    topics: ['Developer productivity', 'Troubleshooting', 'Maven', 'Gradle Build Tool'],
    pardotFormSubmitLink: 'https://go.gradle.com/l/68052/2025-02-25/n3w41b',
    zoomId: '85161564709',
    fullDescription:
      "How much time do you spend each day troubleshooting your builds and tests? \n\nIf you're like most developers, you regularly see dependencies fail, infrastructure explode, non-stop flaky tests, and Checkstyle turning into Gandalf (\"you shall not pass\"). That's ok, right? At least, everything works on your machine. \n\nSarcasm aside, there are many points of failure. We need proper tools to identify and fix the root cause as quickly and easily as possible.\n\nIn this talk by Stefan Wolf, Principal Software Engineer at Gradle, we take a look at troubleshooting local and CI builds with Gradle and Apache Maven. We'll dive deep into observing what is actually happening when various build and test failures arise, and the tools at your disposal for solving them rapidly.",
    discussionTopics: [
      'Using (free) Develocity Build Scan® for Gradle and Maven to provide an X-ray of your project',
      'Use cases of well-known Gradle and Maven OSS projects using Develocity and the proven benefits they see',
      'Develocity demos featuring Gradle Build Tool and Quarkus, diving into observability and troubleshooting tactics',
    ],
    speakers: [
      {
        name: 'Stefan Wolf',
        title: 'Principal Software Engineer',
        company: 'Gradle',
        bio: 'Stefan Wolf started his programming career more than fifteen years ago, and now spends his time as Principal Software Engineer at Gradle. Stefan has been an integral part of the Gradle Build Tool team for the last nine years, which has delivered Build Cache and other features to improve build performance. More recently, Stefan has focused on Develocity Build Scan®, leading the team that delivered artifact transforms and resource usage insights, to help developers troubleshoot builds. As co-maintainer of the popular Jenkins Gradle plugin, Stefan is constantly looking for better automation tools and pathways to save developers time and increase developer joy.',
        headshotLink: 'stefanWolf.jpg',
      },
    ],
    videoLink: 'https://youtu.be/ncFm83La0d4',
    heroImageLink: 'troubleshooting-gradle-maven-builds-develocity.jpg',
  },
  {
    title: 'DPE Summit 2025',
    description: 'The only event dedicated to Developer Productivity Engineering (DPE) and Developer Experience.',
    eventType: 'In-person',
    date: 'September 23-24, 2025',
    time: '09:00 PDT',
    timeStamp: '2025-09-23T09:00:00-07:00',
    locationShort: 'San Francisco, CA',
    isFree: false,
    externalLink: 'https://dpe.org/dpe-summit',
    topics: ['Developer productivity', 'Acceleration', 'Troubleshooting', 'Metrics', 'Gradle Build Tool', 'Android'],
  },
  {
    slug: 'failure-summaries-webinar-develocity-04-16',
    title: 'Why did my build fail? Using AI to troubleshoot faster with Failure Summaries',
    description:
      "In this session with Laurent Ploix, Senior Product Manager at Gradle, you'll see how Failure Summaries can streamline troubleshooting with a deep dive into how to distill complex test failures.",
    eventType: 'Online',
    date: 'April 16, 2025',
    time: '08:00 PST',
    timeStamp: '2025-04-16T08:00:00-07:00',
    locationShort: '',
    isFree: true,
    topics: ['Troubleshooting', 'Metrics', 'Developer productivity'],
    pardotFormSubmitLink: 'https://go.gradle.com/l/68052/2025-03-11/n3wc2q',
    zoomId: '87681701005',
    fullDescription:
      'Most developers tell us that working with CI is frustrating in large projects. Making a change in large projects can generate hundreds of builds with various types of failures—not to mention overall background flakiness. When something goes wrong, troubleshooting issues requires manually navigating several UIs and many large log files.\n\nThis is where "Failure Summaries", an upcoming Develocity feature, comes in to save the day. Built in-house for Gradle engineers, Failure Summaries use AI for categorization and clustering to determine which tests are primarily responsible for a number of failures.\n\nIn this session with Laurent Ploix, Senior Product Manager at Gradle, you\'ll see how Failure Summaries can streamline troubleshooting with a deep dive into how to distill complex test failures.',
    discussionTopics: [
      'What measuring developer productivity looks like, which metrics matter, and how we want to simplify troubleshooting',
      'The effectiveness of extracting CI feedback through the lens of specific evaluation metrics, for example, error log segments, failure clustering into logical groups, root cause identification, and pinpointing infrastructure issues',
      "Concrete examples are seen in action in the Gradle team's own CI environment.",
    ],
    speakers: [
      {
        name: 'Laurent Ploix',
        title: 'Senior Product Manager',
        company: 'Gradle',
        bio: "Laurent recently joined Gradle as a product manager. He joined Spotify in 2014 as a Product Manager for Continuous Integration, coinciding with the company's rapid growth. He then led the Platform Insights team, which focused on understanding productivity through a multi-disciplinary approach combining data science, data engineering, and user research.",
        headshotLink: 'laurentPloix.png',
      },
    ],
    heroImageLink: 'failure-summaries-webinar-develocity.jpg',
  },
  {
    slug: 'Declarative-Gradle-developer-first-approach-to-Gradle-builds-04-25',
    title: 'Declarative Gradle: A developer-first approach to Gradle builds',
    description:
      "In this session, we'll look at developer challenges with understanding and maintaining complex builds and demonstrate our innovative new approach.",
    eventType: 'Online',
    date: 'April 24, 2025',
    time: '08:00 PST',
    timeStamp: '2025-04-24T08:00:00-07:00',
    locationShort: '',
    isFree: true,
    topics: ['Gradle Build Tool', 'Developer productivity'],
    pardotFormSubmitLink: 'https://go.gradle.com/l/68052/2025-03-13/n3wd82',
    zoomId: '88630691060',
    fullDescription: (
      <>
        <p>
          As Uncle Ben once said to <s>Toby Maguire</s> Spider-Man, &quot;With great power comes great
          responsibility&quot;. Here at Gradle we like to say, &quot;With great flexibility comes great power&quot;. And
          with that flexibility comes a learning curve that bends users toward comprehensibility and maintainability.
        </p>
        <br />
        <p>
          This is why we introduced Declarative Gradle in 2024. Now in its third EAP version, this experimental project
          aims to provide an elegant and extensible declarative build language that enables expressing any build in a
          clear and understandable way. So what does Declarative Gradle mean for developers?
        </p>
        <br />
        <p>
          In this session by Paul Merlin and Sterling Greene, long-time software engineers at Gradle, we look at
          developer challenges with understanding and maintaining complex builds. We&apos;ll demonstrate an innovative
          approach that harnesses the expressiveness of Kotlin while imposing restrictions to enhance clarity and
          maintainability. By the end, you&apos;ll better understand the benefits a declarative paradigm for build
          definition brings to project workflows.
        </p>
      </>
    ),
    heroImageLink: 'gbt-webinar-gradle-first-builds.jpg',
    discussionTopics: [
      'Declarative build principles: Understand the advantages of declarative build language and its impact on project maintainability, readability, and collaboration.',
      "Expressive DSL: Explore the DSL's capabilities and how it is a powerful tool for concisely expressing build configuration.",
      'Impact on projects: Explore case studies of projects that use declarative build language, showcasing improvements in understanding, and maintenance.',
    ],
    speakers: [
      {
        name: 'Paul Merlin',
        title: 'Software Engineer',
        company: 'Gradle',
        bio: 'Paul is an experienced, self-made, software engineer with a wide knowledge ranging from computers, network security, and architectures to high-level programming languages, domain modeling, and cryptography. A hobbyist at 7 already, Paul professionally started to build software in 2000.\n\n He joined the Gradle team nine years ago, on time for 2.14, participated in the big performance efforts that went into the 3.x and 4.x versions, led the Gradle Kotlin DSL and Configuration Cache teams since then and is now working on making Gradle more declarative.',
        headshotLink: 'paulMerlin.png',
      },
      {
        name: 'Sterling Greene',
        title: 'Software Engineer',
        company: 'Gradle',
        bio: 'Sterling Greene is a Senior Lead Software Engineer for Gradle. He has helped lead transformative change in large organizations to improve software quality, delivery speed and development costs. Recently, he has been focused on making Gradle easier to use. When he’s not working on Gradle, he spends time making pizzas for his family.',
        headshotLink: 'sterlingGreene.png',
      },
    ],
  },
];
