import clsx from 'clsx';

export type InputProps = {
  label?: string;
  name: string;
  className?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
} & React.InputHTMLAttributes<HTMLInputElement>;

export function Input({ label, name, className, required, inputRef, ...rest }: InputProps) {
  const inputElement = (
    <input
      type="text"
      name={name}
      className={clsx('rounded-md px-4 py-[14px] placeholder:text-gray-4', className)}
      required={required}
      ref={inputRef}
      {...rest}
    />
  );

  if (!label) {
    return inputElement;
  }

  return (
    <label className="flex w-full flex-col gap-3">
      {label} {required && '*'}
      {inputElement}
    </label>
  );
}
